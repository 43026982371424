import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import LogoColored from '../../assets/logoColored.png';
import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { ensureCurrentUser } from '../../util/data';
import { verify } from '../../ducks/emailVerification.duck';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import {
  Page,
  BackgroundImageMvp,
  NamedRedirect,
  LayoutSingleColumn,
  Modal,
} from '../../components';
import loadable from '@loadable/component';
import * as validators from '../../util/validators';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import WelcomePageImage from '../../assets/welcomePageImage.png';
import WelcomePageImageMobile from '../../assets/welcomePageImageMobile.png';
import Whatsapp from '../../assets/whatsapp.jpg';
// import routeConfiguration from '../../../src/routing/routeConfiguration';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { TermsOfServiceContent } from '../../containers/TermsOfServicePage/TermsOfServicePage';
import {
  RESPONSIBILITY,
  RESPONSIBILITY_ENGLISH,
  TOS_ASSET_NAME,
} from '../AuthenticationPage/AuthenticationPage.duck';
import { camelize } from '../../util/string';
import { createResourceLocatorString } from '../../util/routes';
import emailjs from 'emailjs-com';
const RegulationsPage = loadable(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyPage" */ '../../containers/RegulationsPage/RegulationsPage'
  )
);
// import EmailVerificationForm from '../../containers/EmailVerificationPage/EmailVerificationForm/EmailVerificationForm';

import css from './WelcomePage.module.css';
// import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import { Redirect } from 'react-router-dom';
/**
  Parse verification token from URL

  Returns stringified token, if the token is provided.

  Returns `null` if verification token is not provided.

  Please note that we need to explicitely stringify the token, because
  the unwanted result of the `parse` method is that it automatically
  parses the token to number.
*/

const parseVerificationToken = search => {
  const urlParams = parse(search);
  const verificationToken = urlParams.t;

  if (verificationToken) {
    return `${verificationToken}`;
  }

  return null;
};

export const WelcomePageComponent = props => {
  const config = useConfiguration();
  const {
    currentUser,
    intl,
    scrollingDisabled,
    submitVerification,
    isVerified,
    emailVerificationInProgress,
    verificationError,
    location,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
  } = props;

  const [isPressedUpdateMe, setIsPressedUpdateMe] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const [isEnglish, setIsEnglish] = useState(false);
  useEffect(() => {
    // set initial value
    const mediaQueryList = window.matchMedia('(max-width: 767px)');
    setIsMobile(mediaQueryList.matches);

    //watch for updates
    function updateIsMobile(e) {
      setIsMobile(e.matches);
    }
    mediaQueryList.addEventListener('change', updateIsMobile);

    // clean up after ourselves
    return function cleanup() {
      mediaQueryList.removeEventListener('change', updateIsMobile);
    };
  });
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  useEffect(() => {
    if (typeof window !== 'undefined' && window?.location?.pathname?.includes('/en')) {
      setIsEnglish(true);
    }
  }, []);

  const [tosModalOpen, setTosModalOpen] = useState(false);

  const WelcomeText = () => {
    return (
      <>
        <div className={css.language}>
          {!isEnglish && (
            <div>
              <span>עברית</span>
              <span> | </span>
              <a href="/en">English</a>
            </div>
          )}

          {isEnglish && (
            <div>
              <span>English</span>
              <span> | </span>
              <a href="/">עברית</a>
            </div>
          )}
        </div>
        <div className={css.title}>
          {intl.formatMessage({ id: 'WelcomePage.title' })}{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            <img
              src={LogoColored}
              alt="logo"
              height={40}
              style={{ marginBottom: '15px', marginLeft: '3px' }}
            />
            {'!'}
          </span>
        </div>
        <div className={css.textBody}>{intl.formatMessage({ id: 'WelcomePage.textBody1' })}</div>
        <div className={css.explainTitle}>
          {intl.formatMessage({ id: 'WelcomePage.textBody2' })}
        </div>

        <a href={isEnglish ? 'en/l/new' : '/l/new'}>
          <div className={css.priorityLinkLabelLeft}>
            <span className={css.priorityLinkLabelLeftText}>
              {intl.formatMessage({ id: 'WelcomePage.publishButton' })}
            </span>
          </div>
        </a>
        {Array.from({ length: 2 }, (_, index) => {
          const number = index + 1; // Start from 1
          return (
            <div className={css.explainContainer}>
              <div key={number}>
                <div
                  className={css.explainTitle}
                  style={isEnglish ? { textAlign: 'left' } : { textAlign: 'right' }}
                >
                  {intl.formatMessage({ id: `WelcomePage.explainTitle${number}` })}
                </div>
                <div
                  className={css.explainText}
                  style={isEnglish ? { textAlign: 'left' } : { textAlign: 'right' }}
                >
                  {intl.formatMessage({ id: `WelcomePage.explainText${number}` })}
                </div>
              </div>
            </div>
          );
        })}
        <div className={css.explainContainer}>
          <div>
            <div
              className={css.explainTitle}
              style={isEnglish ? { textAlign: 'left' } : { textAlign: 'right' }}
            >
              {intl.formatMessage({ id: `WelcomePage.explainTitle3` })}
            </div>
            <div
              className={css.explainText}
              style={isEnglish ? { textAlign: 'left' } : { textAlign: 'right' }}
            >
              {intl.formatMessage({ id: `WelcomePage.explainText3` })}
              <span
                className={css.insurance}
                onClick={() => setTosModalOpen(true)}
                role="button"
                tabIndex="0"
                onKeyUp={() => {
                  if (e.keyCode === KEY_CODE_ENTER) {
                    () => setTosModalOpen(true);
                  }
                }}
              >
                <FormattedMessage id="WelcomePage.regulations" />
              </span>
            </div>
          </div>
        </div>
        <div className={css.explainContainer}>
          <div>
            <div
              className={css.explainTitle}
              style={isEnglish ? { textAlign: 'left' } : { textAlign: 'right' }}
            >
              {intl.formatMessage({ id: `WelcomePage.explainTitle4` })}
            </div>
            <div
              className={css.explainText}
              style={isEnglish ? { textAlign: 'left' } : { textAlign: 'right' }}
            >
              {intl.formatMessage({ id: `WelcomePage.explainText4` })}
              <div className={css.textBody}>
                {intl.formatMessage({ id: 'WelcomePage.textBody3' })}
              </div>
            </div>
          </div>
        </div>
        <div className={css.explainContainer}>
          <div>
            <div
              className={css.explainTitle}
              style={isEnglish ? { textAlign: 'left' } : { textAlign: 'right' }}
            >
              {intl.formatMessage({ id: `WelcomePage.explainTitle5` })}
            </div>
            <div
              className={css.explainText}
              style={isEnglish ? { textAlign: 'left' } : { textAlign: 'right' }}
            >
              {intl.formatMessage({ id: `WelcomePage.explainText5` })}
            </div>
          </div>
        </div>
        <button onClick={() => setIsPressedUpdateMe(true)}>
          <div className={css.priorityLinkLabelLeft}>
            <span className={css.priorityLinkLabelLeftText}>
              {intl.formatMessage({ id: 'WelcomePage.signButton' })}
            </span>
          </div>
        </button>
      </>
    );
  };

  const EmailMeText = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = e => {
      e.preventDefault();
      if (emailPattern.test(email) && name != '') {
        emailjs
          .send(
            'service_m5zttjd', // Replace with your EmailJS service ID
            'template_ggrpvxa', // Replace with your EmailJS template ID
            {
              from_name: name,
              email: 'urent.site@gmail.com',
              message: email,
              subject: 'מעוניין לקבל עדכון',
            },
            'HEj0aMXWa0rTDG58h' // Replace with your EmailJS user ID
          )
          .then(
            result => {
              console.log('success', result);
            },
            error => {
              console.error('Error sending email:', error);
            }
          );
        setTimeout(() => {
          emailjs
            .send(
              'service_m5zttjd', // Replace with your EmailJS service ID
              'template_f1sr53g', // Replace with your EmailJS template ID
              {
                to_name: name,
                email: email,
                subject: 'ברוכים הבאים ליורנט - המהפכה בדרך!',
              },
              'HEj0aMXWa0rTDG58h' // Replace with your EmailJS user ID
            )
            .then(
              result => {
                console.log('success', result);
              },
              error => {
                console.error('Error sending email:', error);
              }
            );
        }, 5000);
        history.push(
          createResourceLocatorString(
            isEnglish ? 'ThankYouPageEnglish' : 'ThankYouPage',
            routeConfiguration,
            {},
            { enteredEmail: true }
          )
        );
      } else {
        setMessage(intl.formatMessage({ id: 'MailUs.errorMessage' }));
      }
    };

    return (
      <>
        <div className={css.title}>{intl.formatMessage({ id: 'MailUs.title' })}</div>
        <div className={css.subTitle}>{intl.formatMessage({ id: 'MailUs.subTitle' })}</div>
        <div className={css.textBody}>{intl.formatMessage({ id: 'MailUs.textBody1' })}</div>
        <form onSubmit={handleSubmit}>
          <input
            id="name"
            name="name"
            value={name}
            className={css.inputField}
            placeholder={intl.formatMessage({ id: 'ContactDetailsForm.nameLabel' })}
            onChange={e => {
              setName(e.target.value);
              setMessage('');
            }}
            required
          />
          <input
            id="email"
            name="email"
            value={email}
            className={css.inputField}
            placeholder={intl.formatMessage({ id: 'ContactDetailsForm.emailLabel' })}
            onChange={e => {
              setEmail(e.target.value);
              setMessage('');
            }}
            required
          />
          <button type="submit">
            <div className={css.priorityLinkLabelLeft}>
              <span className={css.priorityLinkLabelLeftText}>
                {intl.formatMessage({ id: 'MailUs.SendButton' })}
              </span>
            </div>
          </button>
          {message != '' && <p style={{ color: 'red' }}>{message}</p>}
        </form>
      </>
    );
  };
  const user = ensureCurrentUser(currentUser);

  // The first attempt to verify email is done when the page is loaded
  // If the verify API call is successfull and the user has verified email
  // We can redirect user forward from email verification page.
  // if (isVerified && user && user.attributes.emailVerified) {
  //   return <NamedRedirect name="NewListingPage" />;
  // }
  const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
    // We are just checking the value for now
    console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
  };
  return (
    <Page
      title={intl.formatMessage({ id: 'WelcomePage.welcome' })}
      scrollingDisabled={scrollingDisabled}
      referrer="origin"
    >
      <LayoutSingleColumn mainColumnClassName={css.layoutWrapperMain} topbar={<></>} footer={<></>}>
        <BackgroundImageMvp
          className={css.root}
          childrenWrapperClassName={css.contentContainer}
          as="section"
          image={config.branding.brandImage}
          sizes="100%"
          useOverlay
        >
          <div className={css.container}>
            <img
              src={isMobile ? WelcomePageImageMobile : WelcomePageImage}
              className={css.welcomePageImage}
            />
            <div className={css.content}>
              <div style={{ textAlign: 'center' }}>
                {isPressedUpdateMe ? <EmailMeText /> : <WelcomeText />}

                <div style={{ fontWeight: '600' }}>
                  {intl.formatMessage({ id: 'WelcomePage.ContactUsText' })}{' '}
                  <a
                    target="_blank"
                    href={
                      isEnglish ? 'https://tinyurl.com/ENGURENT' : 'https://tinyurl.com/RentAllDvir'
                    }
                  >
                    <img src={Whatsapp} width={40} height={40}></img>
                  </a>
                </div>
                {isPressedUpdateMe && (
                  <button onClick={() => setIsPressedUpdateMe(false)}>
                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                      {intl.formatMessage({ id: 'MailUs.backButton' })}
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </BackgroundImageMvp>
      </LayoutSingleColumn>
      <Modal
        id="AuthenticationPage.tos"
        isOpen={tosModalOpen}
        onClose={() => setTosModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        isEnglish={isEnglish}
      >
        <TermsOfServiceContent
          inProgress={tosFetchInProgress}
          error={tosFetchError}
          data={
            tosAssetsData?.[camelize(isEnglish ? RESPONSIBILITY_ENGLISH : RESPONSIBILITY)]?.data
          }
        />
      </Modal>
    </Page>
  );
};

WelcomePageComponent.defaultProps = {
  currentUser: null,
  verificationError: null,
};

WelcomePageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  submitVerification: func.isRequired,
  isVerified: bool,
  emailVerificationInProgress: bool.isRequired,
  verificationError: propTypes.error,

  // from withRouter
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isVerified, verificationError, verificationInProgress } = state.emailVerification;
  const { pageAssetsData: tosAssetsData, inProgress: tosFetchInProgress, error: tosFetchError } =
    state.hostedAssets || {};
  return {
    isVerified,
    verificationError,
    emailVerificationInProgress: verificationInProgress,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitVerification: ({ verificationToken }) => {
    return dispatch(verify(verificationToken));
  },
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const WelcomePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WelcomePageComponent);

export default WelcomePage;
