import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const TOS_ASSET_NAME = 'terms-of-service';
export const PRIVACY_POLICY_ASSET_NAME = 'privacy-policy';
export const TOS_ASSET_NAME_ENGLISH = 'terms-of-service-english';
export const RESPONSIBILITY = 'responsibility';
export const RESPONSIBILITY_ENGLISH = 'responsibility-english';

export const loadData = (params, search) => dispatch => {
  const pageAsset = {
    termsOfService: `content/pages/${TOS_ASSET_NAME}.json`,
    termpsOfServiceEnglish: `content/pages/${TOS_ASSET_NAME_ENGLISH}.json`,
    privacyPolicy: `content/pages/${PRIVACY_POLICY_ASSET_NAME}.json`,
    responsibility: `content/pages/${RESPONSIBILITY}.json`,
    responsibilityEnglish: `content/pages/${RESPONSIBILITY_ENGLISH}.json`,
  };
  return dispatch(fetchPageAssets(pageAsset, true));
};
